<template>
  <div>
    <div
      class="blue-bg welcome"
    >
      <span class="text-h2 white--text">
        Welcome, {{ (currentUserInfo && currentUserInfo.givenName) || (currentUserInfo && currentUserInfo.displayName) || 'user' }}.
      </span>
    </div>
    <div class="prediction-card-holder">
      <v-card
        class="prediction-card"
      >
        <v-card-title class="text-h3 title-break">
          {{ $t('PredictionParameterTitle')}}
        </v-card-title>
        <PredictionForm
          @predictComplete="gotoResults"
          style="margin-top: 50px;"
        />
        <!-- <v-btn
          dark
          color="var(--eastman-azure)"
          @click="predict"
          :loading="loading"
          class="mt-8"
        >
          {{ $t('PredictionButtonText') }}
        </v-btn> -->
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AccessAPI from '@/services/AccessServices'

export default {
  components: {
    PredictionForm: () => import('@/components/coatings/PredictionForm.vue'),
  },
  computed: {
    ...mapState(['currentUserInfo'])
  },
  methods:
  {
    gotoResults: function () {
      this.$router.push('/coatings/results')
    },
  },
  mounted: async function () {
    try {
      var requestNames = (await AccessAPI.getUserAccessRequest()).data
      var updatedUserInfo = { ...this.currentUserInfo }
      if (requestNames.fname) {
        updatedUserInfo.givenName = requestNames.fname
      }
      if (requestNames.lname) {
        updatedUserInfo.surname = requestNames.lname 
      }
      if (requestNames.fname && requestNames.lname) {
        updatedUserInfo.displayName = `${requestNames.lname}, ${requestNames.fname}`
      }
      this.$store.dispatch('setUserInfo', updatedUserInfo)
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.welcome {
  height:35vh;
  position: absolute;
  width: 100vw;
  padding-left: 10vw;
  padding-top: 7vh;
}

.prediction-card-holder {
  padding: 20vh 10vw 10vh 10vw;
}

.prediction-card {
  padding: 3em;
}
</style>